import '../styles/admin.scss'
import jquery from "jquery";
window.jQuery = window.$ = jquery;
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'bootstrap'
require('nestable2/jquery.nestable')
window.bootstrap = require("bootstrap");
import * as ActionCable from "@rails/actioncable"
ActionCable.logger.enabled = true

const importAll = (r) => r.keys().map(r)
importAll(require.context('images', false, /\.(png|jpe?g|svg)$/i))

Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.App = {
  turbolinks: false,
  mobile: false,
  locale: "zh-CN",
  notifier: null,
  current_user_id: null,
  access_token: "",
  asset_url: "",
  root_url: "",
  // consumer: consumer,
  cable: ActionCable,

  // 警告信息显示，to 显示在那个 DOM 前 (可以用 css selector)
  alert(msg, to) {
    $(".alert").remove();
    const html = `<div class='alert alert-warning'><button type="button" class="btn-close" data-bs-dismiss="alert"></button>${msg}</div>`;
    if (to) {
      return $(to).before(html);
    } else {
      return $("#main").prepend(html);
    }
  },

  // 成功信息显示，to 显示在那个 DOM 前 (可以用 css selector)
  notice(msg, to) {
    $(".alert").remove();
    const html = `<div class='alert alert-success'><button type="button" class="btn-close" data-bs-dismiss="alert"></button>${msg}</div>`;
    if (to) {
      return $(to).before(html);
    } else {
      return $("#main").prepend(html);
    }
  },

  // 错误信息显示，to 显示在那个 DOM 前 (可以用 css selector)
  danger(msg, to) {
    $(".alert").remove();
    const html = `<div class='alert alert-danger'><button type="button" class="btn-close" data-bs-dismiss="alert"></button>${msg}</div>`;
    if (to) {
      return $(to).before(html);
    } else {
      return $("#main").prepend(html);
    }
  },

  modal(html) {
    let modalWrap = document.querySelector(".root-modal-wrap");
    if (!modalWrap) {
      modalWrap = document.createElement("div");
      modalWrap.className = "root-modal-wrap";
      document.body.append(modalWrap);
    }
    modalWrap.innerHTML = html;
    const modal = new bootstrap.Modal(modalWrap.firstChild);
    modal.show();
  },
};